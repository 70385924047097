<template>
  <div class="doctor">
    <div class="title">医生职称：</div>
    <ul class="list">
      <li @click="select_i(i)" v-for="(i,index) in list" :key="index" :class="{'select': value.length>0&&value.indexOf(i.id)>-1 || value.length===0&&!i.id}">
        {{i.name}}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    list: {
      type: Array,
      default:()=>[]
    },
    value:{
      type: Array,
      default:()=>[]
    }
  },
  methods:{
    select_i(i) {
      console.log(i.id)
      if(!i.id) {
        this.$emit('input',[])
      } else {
        this.$emit('input',[i.id])
      }

    }
  }
}
</script>
<style scoped>
.doctor {
  padding: 26px 18px;
  & .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #323233;
  }
  & .list {
    display: flex;
    flex-wrap:wrap;
    font-size: 13px;
    line-height: 18px;
    color: #323233;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    & li {
      width: 30%;
      padding: 7px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F7F8FA;
      border-radius: 2px;
      margin: 10px 0px;
      &.select {
        background: #1890FF;
        color: #fff;
      }
    }
  }
}
</style>
