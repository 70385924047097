import api from '../../utils/api'
// 登录
export async function get_order_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 7,
  //         data: [
  //           {
  //             online_doctor_id: 1,
  //             online_doctor_name: '医生一',
  //             department: '科室一',
  //             doctor_title: '主任医师',
  //             doctor_role: '医生',
  //             doctor_role_code: 1,
  //             score: 1.5,
  //             inquiry_count: 20000,
  //             avg_response_seconds: 200,
  //             order_id: 1,
  //             medical_card_no: 123,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             inquiry_price: 200
  //           },
  //           {
  //             online_doctor_id: 2,
  //             online_doctor_name: '医生一',
  //             department: '科室一',
  //             doctor_title: '主任医师',
  //             doctor_role: '医生',
  //             doctor_role_code: 1,
  //             score: 1.5,
  //             inquiry_count: 34,
  //             avg_response_seconds: 200,
  //             order_id: 1,
  //             medical_card_no: 123,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             inquiry_price: 200
  //           },
  //           {
  //             online_doctor_id: 3,
  //             online_doctor_name: '医生一',
  //             department: '科室一',
  //             doctor_title: '主任医师',
  //             doctor_role: '医生',
  //             doctor_role_code: 1,
  //             score: 1.5,
  //             inquiry_count: 34,
  //             avg_response_seconds: 200,
  //             order_id: 1,
  //             medical_card_no: 123,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             inquiry_price: 200
  //           },
  //           {
  //             online_doctor_id: 4,
  //             online_doctor_name: '医生一',
  //             department: '科室一',
  //             doctor_title: '主任医师',
  //             doctor_role: '医生',
  //             doctor_role_code: 2,
  //             score: 1.5,
  //             inquiry_count: 34,
  //             avg_response_seconds: 4800,
  //             order_id: 1,
  //             medical_card_no: 123,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             level: '咨询',
  //             inquiry_price: 200
  //           },
  //           {
  //             online_doctor_id: 5,
  //             online_doctor_name: '医生一',
  //             department: '科室一',
  //             doctor_title: '主任医师',
  //             doctor_role: '护士',
  //             doctor_role_code: 2,
  //             score: 1.5,
  //             inquiry_count: 34,
  //             avg_response_seconds: 3600,
  //             order_id: 1,
  //             medical_card_no: 123,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             level: '咨询',
  //             inquiry_price: 200
  //           }
  //         ]
  //       }
  //       // }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/inquiries/doctors', {params:payload})
}

export async function get_departments () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         departments: [
  //           {
  //             code: 1,
  //             name: '大科室1',
  //             secondary: [
  //               {
  //                 code: '1-1',
  //                 name: '分科室1'
  //               },
  //               {
  //                 code: '1-2',
  //                 name: '分科室2'
  //               }
  //             ]
  //           },
  //           {
  //             code: 2,
  //             name: '大科室1',
  //             secondary: [
  //               {
  //                 code: '2-1',
  //                 name: '分科室1'
  //               },
  //               {
  //                 code: '2-2',
  //                 name: '分科室2'
  //               }
  //             ]
  //           },
  //           {
  //             code: 3,
  //             name: '大科室1',
  //             secondary: [
  //               {
  //                 code: '3-1',
  //                 name: '分科室3'
  //               },
  //               {
  //                 code: '3-2',
  //                 name: '分科室3'
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/doctors/departments/categories`)
}
export async function get_doctor_titles () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: [
  //         {
  //           id: 1,
  //           name: '主任医生'
  //         },
  //         {
  //           id: 2,
  //           name: '医师'
  //         },
  //         {
  //           id: 3,
  //           name: '教授'
  //         },
  //         {
  //           id: 4,
  //           name: '主任护士'
  //         },
  //         {
  //           id: 5,
  //           name: '讲师'
  //         }
  //       ]
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/doctors/categories`)
}
