<template>
  <div class="department">
    <ul class="left">
      <li @click="select_big(i)" v-for="(i,index) in list" :key="index" :class="{'select':current_item&&current_item.code===i.code}">
        {{i.name}}
      </li>
    </ul>
    <ul class="right">
      <li v-for="(i,index) in secondary" :key="index+'secondary'" @click="select_small(i)" >
        <span>{{i.name}}</span>
        <van-icon v-if="value.indexOf(i.code)>-1" class="select" name="success" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current_item: null
    }
  },
  computed:{
    secondary() {
      return this.current_item ? this.current_item.secondary : []
    }
  },
  props: {
    list: {
      type: Array,
      default:()=>[]
    },
    value:{
      type: Array,
      default:()=>[]
    },
    search_params: {
      type: Object,
      default:()=>{}
    }
  },
  created(){
    this.current_item = this.hander_item()
  },
  methods: {
    hander_item() {
      for(let i of this.list) {
        for(let item of i.secondary) {
          if(this.value.indexOf(item.code)>-1){
            return i
          }
        }
      }
      return this.list[0]
    },
    select_big(i) {
      this.current_item = i
      this.search_params.departments_name=[i.name]
      if(i.code === '全部科室') this.$emit('input',[])
    },
    select_small(i) {
      this.search_params.departments_name=[i.name]
      this.$emit('input',[i.code])
    }
  }
}
</script>
<style scoped>
.department {
  display: flex;
  min-height: 100px;
  max-height: 350px;
  overflow: auto;
  margin-bottom: 10px;
  & .left{
    width: 125px;
    overflow: auto;
    & li {
      height: 48px;
      line-height: 48px;
      font-size: 13px;
      color: #7D7E80;
      padding-left: 14px;
      background: #F7F8FA;
      &.select {
        background: #fff;
        color: rgba(51, 136, 255, 1);
      }
    }
  }
  & .right{
    flex: 1;
    overflow: auto;
    & li {
      height: 48px;
      line-height: 48px;
      padding:0 14px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #323233;
      & span{
        flex: 1;
      }
      & .select {
        color: rgba(51, 136, 255, 1);
        font-size: 18px;
      }
    }
  }
}
</style>
