<template>
  <div class="g_page_box" style="background: #F2F7F8;">
    <div class="search-area">
      <div class="inner">
        <van-icon name="search" class="name-icon" />
        <input placeholder="请输入医生姓名" class="input-content" v-model="search_params.online_doctor_name"/>
      </div>
      <ul class="tab">
      <li @click="tab_change(i)" v-for="i in tab_list" :key="i.type" :class="{'active': active === i.type}">
        <span>{{i.type === 1 ? (search_params.departments_name.length>0 ? search_params.departments_name[0] : '全部科室') : i.name}}</span>
        <van-icon class="tab-select" name="arrow-down" />
      </li>
    </ul>
    </div>
    <div :class="['dropdown-dialog',{'show-dialog': active}]">
      <div class="inner-dialog"  @click="close"></div>
      <div class="dropdown-content">
        <Departments :search_params="search_params" :list="departments_list" v-model="search_params.departments" v-if="active === 1"/>
        <DortorTitle :list="doctor_titles_list" v-model="search_params.doctor_titles" b- v-else-if="active === 3"/>
        <Sorted v-model="search_params.sorted" v-else/>
      </div>
    </div>
    <div class="g_main_content order" >
      <div class="no-data" v-if="list.length===0&&!loading">
        <img width="185" src="../../assets/img/order/no-date.png"/>
        暂无数据
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="list" v-else>
        <van-list
          :offset="100"
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
        <div v-for="(i,index) in list" :key="index" class="order-i" @click="look_detail(i)">
          <div class="i-heard">
            <div class="head-img">
              <img v-if="i.avatar_url" width="46" height="46" :src="i.avatar_url"/>
              <img v-else width="46" height="46" src="../../assets/img/online/doctor.png"/>
            </div>
            <div class="heard-name">
              <div>{{i.online_doctor_name}}<span style="padding-left: 5px;">{{i.doctor_title_label}}</span></div>
              <span>{{i.department}}</span>
            </div>
            <div :class="['level',{'level2': i.doctor_role === 2}]">{{i.doctor_role === 1 ? '可开药' : '仅咨询'}}</div>
          </div>
          <div class="i-content">
            <div>
              好评率: <van-rate readonly gutter="2px"  :size="10" v-model="i.score" void-icon="star" class="star" color="#ffd21e"/>
            </div>
            <div>
              接诊人次数: <span> {{i.inquiry_count}} </span>
            </div>
            <div>
              平均响应: <span> {{hander_time(i.avg_response_seconds)}} </span>
            </div>
          </div>
          <div class="desc">{{i.desc}}</div>
          <div class="money"><span>¥</span>{{format_money(i.inquiry_price)}}</div>
        </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import {get_order_list,get_departments,get_doctor_titles} from './service'
import Departments from './Departments'
import DortorTitle from'./DortorTitle'
import Sorted from './Sorted'
import {format_money} from '../../utils/format'
export default {
  data () {
    return {
      tab_list:[{type: 1,name:'全部科室'},{type: 2,name:'排序'},{type: 3,name:'筛选'},],
      active: null,
      list: [],
      current_list: [],
      refreshing: false,
      finished:false,
      loading: false,
      timer: null,
      page_size: 10,
      page: 0,
      total: 0,
      error: false,
      search_params: {
        departments: [],
        sorted: 1,
        doctor_titles: [],
        online_doctor_name: '',
        departments_name: []
      },
      departments_list: [],
      doctor_titles_list: [],
      name_timer: null
    }
  },
  components: {
    Departments,
    DortorTitle,
    Sorted
  },
  watch:{
    'search_params.departments'() {
      this.active = null
      this.hander_search()
    },
    'search_params.sorted'() {
      this.active = null
      this.hander_search()
    },
    'search_params.online_doctor_name'() {
      if(this.name_timer) clearTimeout(this.name_timer)
      this.name_timer = setTimeout(()=>{
        this.hander_search()
      },1000)
      this.active = null
    },
    'search_params.doctor_titles'() {
      this.active = null
      this.hander_search()
    }
  },
  created() {
    this.search_order_list()
    this.search_departments()
    this.search_doctor_titles()
  },
  methods: {
    format_money,
    hander_time(time) {
      if(time<60) {
        return 1 + '分钟内'
      } else if(time<3600) {
        return Math.floor(time/60) + '分钟内'
      } else {
        return Math.floor(time/3600) + '小时内'
      }
    },
    tab_change(i) {
      this.active = this.active === i.type ? null : i.type
    },
    hander_search() {
      this.current_list = []
      this.list = []
      this.page = 0
      this.total = 0
      this.finished = false
      this.search_order_list()
    },
    onLoad() {
      if(this.timer) clearTimeout(this.timer)
      if (this.refreshing) {
        this.loading = true
        this.list = []
        this.current_list = []
        this.page = -1
        this.refreshing = false
      }
      if(this.list.length>0&&this.list.length === this.total || this.error || this.total === 0) {
        this.finished = true
        this.error = false
        this.loading = false
        return
      }

      this.timer = setTimeout(()=>{
        this.page = this.page + 1
        this.search_order_list()
      },1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.onLoad()
    },
    look_detail (val) {
      this.$router.push({
        path: '/online-inquiry-detail',
        query: {
          id: val.online_doctor_id
        }
      })
    },
    close () {
      this.active = null
    },
    async search_departments() {
      const {data} = await get_departments()
      data.departments.unshift({
        code: '全部科室',
        name: '全部科室',
        secondary: []
      })
      this.departments_list = data.departments
    },
    async search_doctor_titles() {
      const {data} = await get_doctor_titles()
      data.unshift({
        id: '',
        name: '不限',
      })
      this.doctor_titles_list = data
    },
    async search_order_list () {
      let {page,page_size,search_params} = this
      let params = {page_size,...search_params}
      if(params.departments.length>0) params.departments = params.departments_name
      delete params.departments_name
      params.departments = params.departments.join(',')
      params.doctor_titles = params.doctor_titles.join(',')
      for(let i in params) {
        if(!params[i] || params[i]&&params[i].length === 0) {
          delete params[i]
        }
      }
      params = {...params,page}
      try {
        this.loading = true
        const {data} = await get_order_list(params)
        this.current_list = data.data
        this.total = data.total_elements || 0
        for(let i of data.data) {
          i.score = Math.ceil(i.score)
        }
        this.list = this.list.concat(data.data)
      }catch(e) {
        this.error = true
        console.log(e)
      } finally {
        this.loading =  false
      }

    }
  }
}
</script>
<style scoped>
.order {
  padding-top: 10px;
  position: relative;

}
.list {
  padding:0 10px;
}
.search-area {
  padding: 10px 0 0;
  background: #fff;
  position: relative;
  z-index: 100;
  & .inner {
    height: 34px;
    border-radius: 18px;
    background: #F7F8FA;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 0 16px;
  }
  & .input-content {
    height: 100%;
    background: #F7F8FA;
    &::placeholder{
      font-size: 14px;
      color: #C8C9CC;
      opacity: 0.89;
    }
  }
  & .name-icon {
    font-size: 18px;
    opacity: 0.71;
    color: #969799;
    margin-right: 6px;
    transform: translateY(2px);
  }
}
.tab {
  padding:0 16px;
  display: flex;
  height: 50px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #323233;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  border-top: 1px solid rgba(112, 112, 112, 0.1);

  & >li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-right: 10px;
    & span {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
    }
    & .tab-select {
      color: rgba(150, 151, 153, 1);
      padding-left: 10px;
    }
    &.active{
      color: rgba(24, 144, 255, 1);
      & .tab-select {
        color: rgba(24, 144, 255, 1);
        transform: rotate(180deg);
      }
    }

  }
}
.dropdown-dialog {
  position: absolute;
  right: 0;
  left: 0;
  bottom:0;
  z-index: 10;
  overflow: hidden;
  top: 103px;
  display: none;
  &.show-dialog {
    display: block;
  }
  & .inner-dialog {
    position: absolute;
    animation-duration: 0.2s;
    right: 0px;
    bottom: 10px;
    z-index: 80;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
  }
  & .dropdown-content {
      background: #fff;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      max-height: 60%;
      min-height: 20%;
      position: absolute;
      transition-duration: 0.2s;
      transition: transform .3 ease-out,top .3s ease-out;
      overflow: auto;
    }
}
.order-i {
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 15px 8px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  & .i-heard {
    display: flex;
    align-items: center;
    & .heard-name {
      flex:1;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
      padding-left:12px;
      & >span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #999;
      }
    }
    & .head-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      background: #eee;
      &   img{
         object-fit: cover;
      }
    }
    & .level {
      width: 58px;
      height: 23px;
      background: #44BB00;
      border-radius: 2px;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      line-height: 23px;
      &.level2 {
        background: rgba(0, 136, 255, 1);
      }
    }
  }
  & .i-content {
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
    padding: 10px 0 7px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    &>div {
      display: flex;
      align-items: center;
      & >span {
      padding:0 6px;
      font-size: 14px;
        line-height: 18px;
      font-weight: 500;
      color: rgba(0, 124, 233, 1);
    }
    & .star {
        padding:0 6px 3px;
      }
    }

  }
  & .desc {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 18px;
    color: #323233;
  }
  & .money {
    font-size: 18px;
    font-weight: 600;
    color: #FF6600;
    padding: 10px 0;
    & span {
      font-size: 12px;
      font-weight: 400;
      padding-right: 3px;
    }

  }

}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color:rgba(112, 112, 112, 0.5);
  }
}


</style>
