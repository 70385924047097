<template>
  <ul class="t-order">
    <li @click="select_i(i)" v-for="i in list" :key="i.value" :class="{'t-select':value===i.value}">
      <span>{{i.label}}</span>
      <van-icon v-if="value===i.value" class="select" name="success" />
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      list: [{value: 1,label: '默认排序'},{value: 2,label: '平均回复时间优先'},{value: 3,label: '接诊量优先'},{value: 4,label: '好评率优先'}]
    }
  },
  props: {
    value:{
      type: Number,
      default:null
    },
  },
  methods: {
    select_i(i) {
      this.$emit('input',i.value)
    }
  }


}
</script>
<style scoped>
.t-order {
  & li{
    padding: 15px 23px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #323233;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    display: flex;
    align-items: center;
    &.t-select {
      font-weight: 500;
    }
    & span {
      flex: 1;
    }
    & .select {
      color: rgba(51, 136, 255, 1);
      font-size: 18px;
    }
  }
}

</style>
